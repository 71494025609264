import {Component, OnInit, Input} from '@angular/core';
import menuItems from '../menu-item';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() isHeader = true;
  @Input() lang = 'ru';
  menuItems = menuItems;

  constructor() {
  }

  ngOnInit(): void {
  }

}
