<dl *ngIf="lang ==='en';else ru">
  <dt>Date of birth:</dt> <dd>18 may 1977г.</dd>
  <dt>Country:</dt> <dd>Russia</dd>
  <dt>City:</dt> <dd>Novosibirsk</dd>
  <dt>Education:</dt> <dd>Higher</dd>
  <dt>Main: <dd><a href="http://www.nspu.net/" target="_blank">NSPU</a> ,
  <a href="http://math.nspu.ru/" target="_blank">Mathematical faculty</a>, 1993-1998 г.г.</dd>
  <dd>Postgraduate study 13.00.02 1998-2001 г.г.</dd>
</dl>
<ng-template #ru>
  <dl>
    <dt>Дата рождения:</dt> <dd>18 мая 1977г.</dd>
    <dt>Страна:</dt> <dd>Россия</dd>
    <dt>Город:</dt> <dd>Новосибирск</dd>
    <dt>Образование:</dt> <dd>высшее</dd>
    <dt>Основное: <dd><a href="http://www.nspu.net/" target="_blank">ГОУ ВПО НГПУ</a>,
    <a href="http://math.nspu.ru/" target="_blank">Математический факультет</a>, 1993-1998 г.г.</dd>
    <dd>Аспирантура 13.00.02 1998-2001 г.г.</dd>
  </dl>
</ng-template>
