<img class="me" src="../../assets/img/me.jpg" alt=""/>
<h1 *ngIf="lang === 'en'; else ru">Welcome!</h1>
<p></p>
<ng-template #ru>
  <h1>Добро пожаловать!</h1>
  <p class="fl">
    Здравствуйте, меня зовут Котов Егор. Я разрабатываю интернет приложения различного уровня сложности.
  </p>
</ng-template>
<a href="/"><img class="icons django_ico" src="../../assets/img/icons/django.png" alt="Django"/></a>
<img class="icons python_ico" src="../../assets/img/icons/python.png" alt="Python"/>
<img class="icons mysql_ico" src="../../assets/img/icons/mysql.png" alt="Mysql"/>
<img class="icons HTML5_ico" src="../../assets/img/icons/HTML5.png" alt="HTML5"/>
<img class="icons css3_ico" src="../../assets/img/icons/css3.png" alt="css3"/>
<img class="icons php_ico" src="../../assets/img/icons/php.png" alt="php"/>
<img class="icons angular_ico" src="../../assets/img/icons/angular.svg" alt="php"/>
<a href="http://vuejs.go-sha.ru/"><img class="icons vue_ico" src="../../assets/img/icons/vue.png" alt="php"/></a>
<img class="icons postgresql_ico" src="../../assets/img/icons/postgresql.png" alt="php"/>
