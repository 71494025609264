import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  slidesStore = [
    '../../assets/img/shot/thumb/35express.jpg',
    '../../assets/img/shot/thumb/accentskinhealth.jpg',
    '../../assets/img/shot/thumb/adrenaclick.jpg',
    '../../assets/img/shot/thumb/anprometei.jpg',
    '../../assets/img/shot/thumb/alueur-candles.jpg',
  ];
  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    center: true,
    nav: false
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}
