import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export const DEFAULT_LANG = 'ru';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  currentLang = DEFAULT_LANG;

  constructor() {
  }

  lang$: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_LANG);

  setLang(lang: string) {
    this.currentLang = lang
    this.lang$.next(this.currentLang);
  }
}
