<div id="app">
  <div class="header_wrapper">
    <header>
      <a href="https://go-sha.ru/" class="logo">GO-SHA.RU</a>
      <p class="ek" *ngIf="lang === 'ru';else en">Котов Егор</p>
      <ng-template #en><p class="ek">Egor Kotov</p></ng-template>
      <div class="lang_button_wrapper">
        <a class="lang_button" [ngClass]="(lang === 'en') ? 'active' : ''" (click)="changeLang('en')">EN</a>
        <a class="lang_button" [ngClass]="(lang === 'ru') ? 'active' : ''" (click)="changeLang('ru')">RU</a>
      </div>
      <div class="social_wrapper">
        <a href="#" class="ico google"></a>
        <a href="http://vk.com/ekotov77" class="ico vk"></a>
        <a href="mailto:mail@go-sha.ru" class="ico mail"></a>
        <a href="#" class="ico facebook"></a>
      </div>
      <app-menu [lang]="lang"></app-menu>
    </header>
  </div>
  <div class="page">
    <section class="content">
      <div class="bg_content"></div>
      <div id="content">
        <app-page-header [title]="title"></app-page-header>
        <div class="ct_wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </section>
  </div>
  <div class="footer_wrapper">
    <footer>
      <a href="/" class="logo"></a>
      <app-menu [isHeader]="false" [lang]="lang"></app-menu>
    </footer>
  </div>

</div>
