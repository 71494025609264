  <p>
    Если у вас есть желание сотрудничать со мной, то можете воспользоваться формой или другими приведёнными способами связи.
  </p>
  <div class="clearfix">
    <div class="col">
      <form  (ngSubmit)="submit()" class="contact_form" [formGroup]="contactForm">
        <p><label for="name">Ваше имя: </label>
          <input id="name" class="form-control" name="name" formControlName="name" type="text" /></p>
        <p><label for="email">Ваш email: </label>
          <input id="email" class="form-control" name="name" formControlName="email" type="text" /><br /><p>
        <p><label for="message" style="width: 140px;">Ваше предложение: </label><br />
        <textarea class="form-control" name="message" formControlName="message" id="message"></textarea>
        </p>
        <div class="text-right"><input type="submit"></div>
      </form>
    </div>
    <div class="col links">
      <a href="mailto:mail@go-sha.ru"><img src="../../assets/img/icons/mailicon.png" class="mailicon">mail@go-sha.ru</a><br /><br />
      <img src="../../assets/img/icons/skype.png" class="skype"><span>egor.kotov77</span><br /><br />
      <a href="https://vk.com/ekotov77" target="_blank"><img src="../../assets/img/icons/vk.png" class="vk">ekotov77</a><br /><br />
      <img src="../../assets/img/icons/geo.png" class="geo"><span>Новосибирск</span><br /><br />
    </div>
  </div>
