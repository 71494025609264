const menuItems = {
  ru: [
    {link: '/', title: 'Главная'},
    {link: '/portfolio', title: 'мои работы'},
    {link: '/about', title: 'о себе'},
    {link: '/contacts', title: 'контакты'},
  ],
  en: [
    {link: '/', title: 'Home'},
    {link: '/portfolio', title: 'portfolio'},
    {link: '/about', title: 'about me'},
    {link: '/contacts', title: 'contacts'},
  ]
};

export default menuItems;
