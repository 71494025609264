<div [ngClass]="(isHeader == true)?'header':'footer'" class="menu_wrapper">
  <div class="menu clearfix">
    <span *ngIf="!isHeader">•</span>
    <a *ngFor="let item of menuItems[lang]"
       [routerLink]="[item.link]"
       [routerLinkActiveOptions]="{exact: true}"
       routerLinkActive #rla="routerLinkActive">
      <span *ngIf="isHeader" class="m_arrow" [ngClass]="rla.isActive ? 'vis' : ''"></span>{{item.title}}
      <span *ngIf="!isHeader">•</span>
    </a>
  </div>
</div>
