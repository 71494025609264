import {Component, OnInit} from '@angular/core';
import menuItems from './menu-item';
import {Router} from '@angular/router';
import {LangService} from './lang.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  windowTitle = ' | Разработка Web проектов | Егор Котов';
  title: string;
  lang = 'ru';
  menu = menuItems;

  constructor(private router: Router, private langService: LangService, private titleService: Title) {
    this.langService.lang$.subscribe(val => {
      this.lang = val;
    });
    router.events.subscribe(val => {
      this.setTitle();
    });
  }

  changeLang(lang) {
    this.langService.lang$.next(lang);
    this.setTitle();
  }

  upperFirst(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  setTitle() {
    this.title = this.menu[this.lang].find(item => item.link === this.router.url).title;
    this.titleService.setTitle(this.upperFirst(this.title) + this.windowTitle);
  }
}
